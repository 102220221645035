@import './vars';


@media screen and (max-width: 1440px) {
}


@media screen and (max-width: 1024px) {
    .red_share{
        margin-bottom: -30px;
    } 
    .red_copyright_box-mail{
        margin-top: -30px;
    }
    .red_header{
        height: 320px;
    }
    .red_blueSite{
        padding: 40px 15px;
    }
    .red_blueWrapper{
        width: 100%;
    }
    .red_blueSidebar{
        display: none;
        flex-direction: column;
        width: 350px;
        padding: 14px;
        position: absolute;
        background: var(--color2);
        z-index: 2000;
        left: 0;
        top: 0;
        margin-right: 0;
        border: 4px solid #fff;
        border-radius: 0 10px 10px 0;
        z-index: 2000000;
        padding-top: 90px;
    }
    .red_header_inner{
        display: grid;
    }
    .red_header_menu{
        margin-bottom: 40px;
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    } 
    .red_mobile__button{
        display: flex;
        justify-self: end;
    }
    .red_track-info, .red_track-central{
        height: 70px;
    }
    .red_mute-box__muteUnmute{
        display: none;
    }
    .red_header_menu a{
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .red_mute-box{
        display: none;
    }

    .red_letter:hover .red_letter__cnt{
        z-index: 2;
        left: 0;
    }
    .pleer{
        width: 90%;
    }
}

@media screen and (max-width: 992px) {
    .red_melodious__list a{
        font-size: 24px;
    }
    .red_track-info{
        margin-right: 60px;
    }
    }
@media screen and (max-width: 920px) {
}

@media screen and (max-width: 768px) {
    .red_card{
        height: 90px;
        padding: 0 0 0 10px;
    }
    .red_blueSite{
        padding: 40px 0;
    }
    .ya-share2__container{
        text-align: center;
        margin-bottom: 20px;
        margin-top: -15px;
    }
    #main-awesome_search__form{
        width: auto;
    }
    .red_header{
        margin-bottom: 40px;
    }
    .red_share{
        flex-direction: column;
    }
    .pleer{
        width: 100%;
        box-sizing: border-box;
    }
    .track{
        padding: 25px 0 0;
    }
    .play::before,.pause::before{
        margin: 0 7px;
    }
    .red_track-central_left-btn svg, .red_track-central_right-btn svg{
        width: 35px;
        height: 35px;
    }
    .pause::before, .play::before{
        width: 65px;
        height: 65px;
    }
    .seo-title--footer{
        margin-bottom: 145px;
    }
    .red_bluePopular_title{
        font-size: 25px;
    }
    .red_player-title_heading{
        margin-right: 0;
        font-size: 25px;
    }
    .red_track__leftWrapper{
        justify-self: end;
    }
    .red_player-title{
        justify-content: center;
    }
    .red_track-central{
        margin-right: 0;
    }
    .red_results-info__card{
        padding: 15px 10px;
    }
    .red_melodious__list{
        flex-direction: row;
    }
    .red_melodious__list a{
        margin-right: 5px;
        margin-left: 5px;
    }
    .red_boxline__bottom{
        text-align: center;
    }
    .red_mute-box{
        margin-right: 30px;
    }
    .red_about{
        flex-direction: column;
    }
    .red_logo-link{
        display: none;
    }
    .red_wrapper{
        margin-left: 0;
    }

    .red_mobile-nav__button{
        width: min-content;
        justify-self: end;
    }
    .red_song-aplhabet{
        width: auto;
    }
    .red_popular_title{
        font-size: 25px;
    }
    .red_popular_logo{
        width: 40px;
        height: 40px;
    }
    .red_copyright_box-mail{
        font-size: 24px;
    }
    .red_sidebar{
        display: none;
    }
    .red_wrapper{
        width: 100%;
    }
    .red_melodious__list a::before{
        display: none;
    }
    .red_footer_copy{
        width: 100%;
    }
}

@media screen and (max-width: 624px) {
    .red_letter__logo{
        fill: var(--color2);
        stroke: var(--color2);
    }
    .red_letter__title{
        color: var(--color2);
    }
    .ya-share2__list{
        margin-top: 60px !important;
    }
    .red_card_download{
        bottom: 23px;
    }
    .red_card-subtext{
        bottom: auto;
    }
    .red_card{
        height: 70px;
    }
    .red_card_link{
        margin-right: 10px;
    }
    .red_card_link::before{
        width: 50px;
        height: 50px;
    }
    .red_card_download svg{
        width: 45px;
        height: 45px;
    }
    .red_header{
        height: 365px;
    }
    .red_header_menu{
        display: flex;
        flex-direction: column;
    }
    .red_blueSidebar{
        width: 100%;
    }
    .red_player-title_heading{
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .red_copyright_box-mail a{
        margin-left: 0;
    }
    .track{
        flex-direction: column;
    }
    .social-box{
        right: 30px;
    }

    .red_about{
        margin-bottom: 190px;
    }
    .red_track-central{
        margin-right: 0;
    }
    .red_track-info{
        margin-bottom: 10px;
    }
    .red_mute-box {
        width: auto;
        justify-self: center;
        grid-column-start: 2;
        margin-right: 0;
    }
    .red_time{
        margin-right: 15px;
    }
    .red_zero-time{
        margin-left: 0;
        font-size: 16px;
    }
    .red_time_space{
        font-size: 16px;
    }
    .red_timeBox{
        margin-right: 0;
        font-size: 16px;
    }
    .red_copyright_box-mail{
        flex-direction: column;
    }
}

@media screen and (max-width: 670px) {
    .import_ser-melodious__list a{
        justify-content: center;
    }
}

@media screen and (max-width: 577px) {

    .red_xpopular_logo{
        display: none;
    }
    .red_popular_title{
        text-align: center;
    }
    .red_sotial-network-vk_btn{
        width: 100%;
        margin-bottom: 20px;
    }
    .red_sotial-network-vk_link{
        width: 100%;
    }
    .red_sotial-network-vk_input{
        flex-direction: column;
    }


    .red_download_vk{
        width: 100%;
    }
    .red_vk{
        width: 100%;
        min-width: auto;
    }
    .red_social-box{
        padding-bottom: 0;
    }
    .red_player-title_heading{
        margin-right: 0;
    }
    .red_results-info__card_download{
        width: 40px;
        height: 40px;
    }
}    


@media screen and (max-width: 510px) {
    .seo-title--footer{
        margin-bottom: 170px;
    }
    .red_bluePopular{
        margin-top: 35px;
    }
    .red_blueSite{
        padding: 0;
    }
    .red_track-info{
        margin-right: 0;
    }
    .red_player-title{
        margin-top: 0;
    }
    .red_copyright_box-mail{
        font-size: 22px;
        margin-top: 10px;
    }
    .red_player-title_heading{
        font-size: 20px;
        text-align: center;
        margin-top: 0;
    }
    .progress-bar__container{
        flex-direction: column;
    }
    .red_blueSocial_vk{
        display: none;
    }
    .res-card_st-bf_results-info__card-subtext, .red_subinfo__time{
        display: none;
    }
    .red_player-title{
        text-align: center;
        margin-left: 0;
        justify-content: center;
        margin-bottom: 0;
    }
    .red_description{
        margin-bottom: 20px;
    }
    .track{
        display: flex;
        flex-direction: column;
    }
    .red_mute-box{
        display: none;
    }
    .red_track-info{
        flex-direction: column;
        align-items: center;
    }

}

@media screen and (max-width: 470px) {
    .red_card_download{
        right: 20px;
    }
    .red_card-subtext{
        display: none;
    }
    .red_card_artist::after{
        margin-left: 5px;
    }
    .red_card_name{margin-right: 55px;}
    .red_card_artist{
        margin-right: 5px;
    }
    .red_bluePopular__box{
        display: none;
    }
    .red_player-title_heading{
        font-size: 18px;
    }
}

@media screen and (max-width: 425px) {
    .red_letter{
        display: none;
    }
    .red_header{
        height: 345px;
    }
    .red_header_menu a{
        width: 100%;
    }
    .seo-title--footer{
        margin-bottom: 170px;
    }
    #main-awesome_search__form{
        margin-bottom: 55px;
    }
    .red_bluePopular{
        margin-top: 0;
    }
    .red_sotial-network-vk_content{margin-bottom: 100px;}
    .red_card{height: 90px;}
    .submit{
        padding-left: 0;
        padding-right: 0;
    }
    
    .ya-share2__container{
        display: none;
    }
    // .red_description_title{
    //     display: none;
    // }
    .music-artist__card_artist, .music-artist__card_tracklink{
        max-height: 17px;
    }
    .red_header_menu{
        flex-direction: column;
        align-items: center;
    }
    .red_header_menu a{
        font-size: 24px;
    }
    .music-artist__card_download svg{
        width: 40px;
        height: 25px;
    }
    .music-artist__card_download{
        width: 40px;
        height: 20px;
    }
    .title{
        font-size: 14px;
    }
    .music-artist__card{
        height: 70px;
        padding: 10px 5px 5px 5px;
    }
    .music-artist__card_time{
        display: none;
    }
    .music-artist__card .start{
        transform: scale(0.6);
    }
    .music-artist__card_download{
        position: static;
    }
    .music-artist__card-subtext{
        display: none;
    }
    .res-card_st-bf_results-info__card{
        padding: 15px 10px;
    }
    .red_popular__box{
        justify-content: center;
    }
    .red_melodious__heading{
        text-align: center;
    }
    .red_description_title{
        text-align: center;
    }
    .red_results-info__card_time{
        margin-right: 0;
    }
    .red_results-info__card-subtext{
        margin-right: 10px;
        margin-left: 15px;
    }
    .import_ser-results-info__card_artist, .import_ser-results-info__card_space, .import_ser-results-info__card_tracklink{
        font-size: 14px;
    }
    .import_ser-results-info__card_like{
        display: none;
    }
    
    .import_ser-results-info__card_download svg{
        width: 30px;
        height: 30px;
    }
    .red_results-info__card.pausing a.import_ser-results-info__card_link::before {
        content: url(../images/musicTheme-play.svg);
        padding-left: 12px;
        padding-right: 8px;
        height: 22px;
    }
    .red_results-info__card_link::before{
        width: 20px;
        height: 20px;
        padding: 11px;
    }
    .red_results-info__card .start{
        width: 40px;
        height: 40px;
    }
    .red_vk::before{
        min-width: calc(300px + 6px);
    }
    .red_melodious__list{
        display: flex;
        flex-direction: column;
    }
    .red_melodious__list a{
        font-size: 30px;
    }
    .red_header_logo-link{
        font-size: 30px;
    }
}

@media screen and (max-width: 375px) {
    .main-awesome_input-content{
        width: 210px;
    }
    .ova-menu-decor-icon svg{
        margin-right: 10px;
        width: 30px;
        height: 30px;
    }
    .red_header_logo-link{
        font-size: 28px;
    }
    .red_header{
        padding: 5px;
    }
    .red_mobile__button-line{
        margin-bottom: 4px;
        margin-top: 4px;
        width: 35px;
        width: 35px;
    }
} 