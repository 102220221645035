@import "./vars";


@mixin scrollbars($size, $foreground-color, $foreground-color-hover, $background-color: mix($foreground-color, white,  50%), $radius: 0) {
}

/*Border Component END*/

/* can delete*/
.red_header_logo{
  display: flex;
  align-items: center;
}

.seo-title--footer{
  margin-bottom: 190px;
}

.ova-menu-decor-icon svg{
  width: 35px;
  height: 35px;
  fill: var(--color-1);
  margin-right: 15px;
}

.ova-menu-decor-icon{
  height: 30px;
}

.red_share{
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
}

/* can delete*/

.red_card_name{
  margin-right: 170px;
}

.main-awesome_input-content{
  color: var(--color2);
  font-weight: 700;
   -webkit-text-fill-color: #ED4159;
}

.main-awesome_input-content:focus{
  text-transform: uppercase;
}

ul{
  padding: 0;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  // font-family: 'Inter', sans-serif;
  font-family: 'Neucha', cursive;
  font-size: 16px;
  background: var(--color-2);
  letter-spacing: 1.5px;
}

.red_blueSocial{
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.red_blueSite{
  display: flex;
}

.main-awesome_input{
  width: 100%;
&-content{
  font-size: 20px;
  border: none;
  width: 100%;
  &:hover,&:focus, &:active {
    border: none;
    outline: 0;
    outline-offset: 0;
  }
  &::placeholder{
    font-weight: 700;
    color: var(--color-1);
  }
}
}

.red_letter__cnt{
  margin-top: 60px;
}

.red_letter{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  &:hover{
    cursor: pointer;
    .red_letter__cnt{
      display: block;
      position: absolute;
      background: var(--color2);
      padding: 8px;
      border-radius: 5px;
      
    }
  }
  &__link{
    color: var(--color-4);
    font-size: 18px;
    margin: 6px;
    &:hover{
      text-decoration: underline;
      font-weight: 700;
    }
  }
  &__heading{
    display: flex;
  }
  &__title{
    margin: 0;
    display: flex;
    color: var(--color-1);
    font-size: 32px;
  }
  &__logo{
    margin-left: 22px;
    fill: var(--color-1);
    width: 43px;
    height: 43px;
    stroke: var(--color-1);
  }
}

.red_mobile__button {
  display: none;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  z-index: 9999999;
  flex-direction: column;
  &-line{
      margin-bottom: 5px;
      margin-top: 5px;
      display: block;
      position: relative;
      width: 40px;
      height: 4px;
      border-radius: 10px;
      background-color: var(--color-1);
  }
}

.red_blueContainer {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;
}

.red_sotial-network-vk{
  display: flex;
  flex-direction: column-reverse;
}

.red_header{
  background: var(--color2);
  padding: 20px;
  height: 150px;
  margin-bottom: 70px;
  &_menu{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 60px;
    row-gap: 20px;
  }
  &_menu a{
    color: var(--color-4);
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 27px;
    position: relative;
    &::before{
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      padding-top: 52px;
      border-bottom: 2px solid #f9dd94;
    }
    &:hover::after{
      display: flex;
      justify-content: center;
      content: "";
      display: block;
      width: 45px;
      position: absolute;
      padding-top: 50px;
      border-bottom: 7px solid #f9dd94;
      }
    &:hover{
      color: var(--color-1);
    }
    
   
  }
  &_inner{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_logo-link{
    font-size: 40px;
    font-weight: 900;
    color: var(--color-1);
  }
}

.red_blueWrapper{
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  width : calc(100% - 340px)
}

.red_bluePopular{
  padding: 0 15px;
  flex-direction: column;
  display: flex;
  &__box{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_title{
    font-weight: 700;
    color: var(--color2);
    font-size: 40px;
    margin-right: 30px;
  }
}



.pages{
  color: var(--color2);
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
  font-weight: 700;
  margin-bottom: 300px;
  & a{
    color: var(--color2);
    &:hover{
      text-decoration: underline;
    }
  }

}

.red_result-of-search:hover{
  text-transform: uppercase;
}

.active{
  display: flex !important;
}

.red_result-of-search{
  padding: 20px 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: 4px solid var(--color2);
  border-radius: 10px;
  &_link{
    font-size: 22px;
    color: var(--color2);
    margin-left: 20px;
  }
  &_box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.red{
  display: flex;
  flex-direction: column;
  p{
    font-size: 28px;
    text-align: center;
    color: #fff;
  }
  &_card_artist, &_card_tracklink{
    overflow: hidden;
    max-height: 20px;
  }
  &_card{
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 700;
    font-size: 19px;
    height: 100px;
    display: inline-flex;
    align-items: center;
    position: relative;
    transition: all .3s ease;
    padding: 15px;
    border-bottom: 2px solid var(--color2);
    margin-bottom: 15px;
    border-radius: 10px;
    &_artist{
      margin-right: 5px;
    }
     
  
  }
  &_card.pausing a.red_card_link::before{
    content: url('../images/card-play.svg');
  }
  &_card.plays a.red_card_link::before{
    content: url('../images/card-pause.svg');
  }
  &_card_like{
    height: 30px;
  }
  &_card .start {
    cursor: pointer;
} 
  &_card-subtext{
    position: absolute;
    right: 95px;
    bottom: 35px;
  }

  &_card_time{
    font-size: 16px;
    color: var(--color-1);
  }
  &_card_share{
    right: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 26px;
  }
  &_card_artist, &_card_tracklink{
    color: var(--color2);
    width: 100%;
    margin-right: 20px;
  }
  &_card_download{
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    fill: var(--color2);
    position: absolute;
    right: 40px;
    bottom: 35px;
    svg{
      width: 57px;
      height: 54px;
    }
  }
  &_card_link{
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-right: 20px;
    color: var(--white-color);

    &::before {
      content: url('../images/card-play.svg');
      width: 60px;
      height: 60px;
    }
  }
}



.red_card_artist::after{
  content: ' - ';
  margin-left: 20px;
}

.red_music{
  &__title{
    margin-bottom: 25px;
    display: flex;
    align-items: center;
  }
  &__title-heading{
    text-align: center;
    width: 100%;
    color: var(--color-1);
    font-size: 25px;
    letter-spacing: 1.1pt;
    position: relative;
    border-bottom: 1px solid var(--color-1);
    padding: 0 17px 10px 0;
    margin: 20px 0 10px 0;
    &::after{
      content: '';
      background: var(--color-1);
      border-radius: 50px;
      position: absolute;
      bottom: -7px;
      right: 0;
      width: 14px;
      height: 14px;
    }
  }
  &__title-logo{
    width: 32px;
    height: 36px;
    fill: var(--color-1);
    margin-right: 30px;
  }
}

.submit {
  cursor: pointer;
    transition: all .2s ease;
    border: none;
    background: 0 0;
    height: 100%;
    padding-left: 15px;
    display: flex;
    align-items: center;
  svg {
    width: 30px;
    height: 30px;
    transition: all 0.6s;
    fill: var(--color2);
  }
}

.dur {
  font-size: 18px;
  color: var(--color2);
}

.title {
 overflow: hidden;
}

.red_logo-link{
  font-size: 35px;
  font-weight: 700;
  color: var(--color-1);
}

.red_footer{
  margin-top: 10px;
  &_wrapper {
    font-size: 20px;
    position: relative;
    color: var(--color-1);
  }
  &_copy{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.red_player-link{
  font-size: 30px;
  color: #fff;
}

.red_story_track-title{
  width: calc(100% - 35px);
  word-wrap: break-word;
}

.red_story{
  &_heading{
    text-align: center;
    width: 100%;
    color: var(--color-1);
    font-size: 25px;
    letter-spacing: 1.1pt;
    position: relative;
    border-bottom: 1px solid var(--color-1);
    padding-bottom: 10px;
    margin: 20px 0 10px 0;
    &::after{
      content: '';
      background: var(--color-1);
      border-radius: 50px;
      position: absolute;
      bottom: -7px;
      right: 0;
      width: 14px;
      height: 14px;
    }
  }
  &_track{
    display: flex;
    flex-direction: column;
  }
  &_track-link{
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    display: flex;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    &:hover{
      background-color: var(--color-1);
      text-transform: uppercase;
    }
  }
  &__track-img{
    height: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;  
    color: var(--white-color);
    i{
      font-size: 30px;
    }
  }
  &__track-title{
    font-size: 20px;
    font-weight: 700;
  }
}

.red_description{
  width: 450px;
  font-size: 22px;
  &_content{
    color: var(--color2);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    margin-bottom: 30px;
  }
  &_icon{
    font-size: 25px;
    margin-right: 10px;
  }
  &_title{
    display: flex;
    } 
  }

.red_boxline{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.red_copyright_box{
  margin-top: 20px;
  &-logo{
    padding: 0 10px;
  }
  &-mail{
    column-gap: 10px;
    margin-top: 50px;
    margin-bottom: 20px;
    color: var(--color2);
    display: flex;
    justify-content: center;
    font-size: 30px;
    align-items: center;
    text-align: center;
    a{
      color: var(--color2);
      font-weight: 700;
      font-size: 30px;
    }
  }
  &-link{
    color: var(--color-14);
  }
}

.red_social-box--btnBox {
  flex-wrap: wrap;
  position: relative;
  padding: 5px;
}

.about{
  &__inner{
    margin-bottom: 20px;
    padding: 5px;
    color: var(--color-2);
    
  }
}

.boxline{
  margin: 10px 0;
  &__top p{
    line-height: 1.5;
    font-size: 20px;
    color: #fff;
  }
  &__bottom{
    font-size: 20px;
    font-weight: 700;
    color: var(--white-color);
    text-align: center;
  }
}

 .red_sotial-network-vk {
  &_btn{
    border-radius:10px;
    height: 70px;
    background-color: var(--color2);
    width: 100%;
    font-size: 23px;
    text-align: center;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    &::placeholder{
      color: #fff;
    }
  }
  &_link{
    border-radius: 10px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    border: 4px solid var(--color2);
    color: var(--color2);
    position: relative;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: color 150ms ease-in-out;
    transition: color 150ms ease-in-out;
    height: 60px;
    display: flex;
    width: 100%;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: var(--color-1);
      z-index: -1;
      -webkit-transition: width 150ms ease-in-out;
      transition: width 150ms ease-in-out;
    }
    &:hover::after{
      width: 110%;
    }
  }
  &_input{
    padding: 0 15px;
    margin-bottom: 20px;
      display: flex;
      flex-direction: column;
  }
  &_content{
    line-height: 2;
    font-size: 20px;
    color: var(--color2);
    font-weight: 700;
  }
}

.active__selection::marker,.active__genre::marker{
  color: var(--color-1);
}
.active__selection,.active__genre{
  font-weight: 700;
  color: var(--color-1);
  font-size: 18px;
  margin-bottom: 5px;
} 

#genre{
  padding: 25px 0 0;
  list-style-type: none;
  position: relative;
}

.red_genre{
  &-list{
    display: flex;
    flex-direction: column;
  }
  &-item{
    display: flex;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    &:hover{
      background-color: var(--color-1);
      text-transform: uppercase;
    }
    // &::before{
    //   content: ">";
    //   color: var(--color-4);
    //   font-size: 20px;
    //   margin-right: 10px
    //   }
    &_link{
      font-size: 20px;
      color: var(--color-4);
    }
  }
  &-item_logo{
    margin-right: 15px;
    font-size: 25px;
    color: var(--color-14);
  }
}
.active__selection{
  list-style: none;
}

#trackAjax {
  z-index: 1;
  padding: 0;
}

#main-awesome_search__form {
  padding: 10px 15px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  border: 2px solid var(--color2);
  height: auto;
  width: 370px;
}



.pleer {
    z-index: 99999;
    bottom: 20px;
    left: 0;
    right: 0;
    position: fixed;
    display: flex;
    width: 80%;
    align-items: flex-start;
    margin: 0 auto;
    border: 5px solid var(--color2);
    transition: all .6s ease;
    border-radius: 10px;
    background-color: #fff;
}



.red_volume {
  margin-left: 5px;
  cursor: pointer;
    padding: 0;
    overflow: hidden;
    width: 100%;
    height: 15px;
    border: 3px solid var(--color2);
    margin-top: 0;
    border-radius: 25px;
    z-index: 202;
    position: relative;
  .red_volume__line {
    position: absolute;
    top: 0;
    cursor: pointer;
    width: 25%;
    height: 15px;
    float: left;
    -webkit-transition: width 0.7s ease;
    transition: width 0.7s ease;
    background: var(--color2);
    border-radius: 25px;
  }
}



.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

a.play {
  color: var(--white-color);
  //border: none;

  &:hover {
    text-decoration: none;
  }
}

a.pause {
  color: var(--white-color);
}


.play,.pause{
  display: flex;
  align-items: center;
  justify-content: center;
}
.play {
  &::before {
    content: url("../images/card-play.svg");
    width: 90px;
    height: 90px;
    margin: 0 10px;
  }
}
.pause {
  &::before {
    content: url('../images/card-pause.svg');
    width: 90px;
    height: 90px;
    margin: 0 10px;
  }
}

.red_track-central_left-btn,
.red_track-central_right-btn{
  svg{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  fill: var(--color-1);
  }
}


.red_letter__cnt{
  border: 2px solid var(--color-4);
  display: none;
  right: 0;
  width: 250px;
}

.red_track-central_right-btn svg{
  transform: rotate(180deg);
}

.uil{
  cursor: pointer;
}

.red_track-central{
  align-items: center;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin-right: 30px;
}

.progressBarContainer-playBar{
  position: relative;
  display: inline-block;
  height: 11px;
  background: var(--color-2);
  color: var(--color-2);
}

.progressBarContainer--downloadBar{
  display: block;
  position: absolute;
  height: 70px;
  width: 0;
}

.download-bar{
  display: block;
  height: 70px;
  width: 100px;
  z-index: 201;
  border-radius: 20px;
}

.progress-bar__container-playbar {
  position: absolute;
  left: 0;
  display: inline-block;
  height: 20px;
  background: var(--color-1);
  color: var(--color-2);
  align-items: center;
  //border-radius: 10px;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: var(--white-color);
    border: 2px solid #061161;
    display: none;
  }
}

.track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0 10px;
}

.track__track-timebox {
  position: absolute;
    width: 100%;
    height: 20px;
    background: #fff;
    border-bottom: 5px solid var(--color2);
    margin-top: 25px;
}

.progress-bar__container {
  cursor: pointer;
  transition: height linear 0.3s;
  height: 25px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.red_zero-time {
  font-size: 14px;
}

.red_timeBox {
  margin-right: 18px;
  font-size: 14px;
}

.red_player-title_heading {
  z-index: 2;
  text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    align-items: center;
    font-weight: 700;
    flex-wrap: wrap;
    overflow: hidden;
    font-size: 30px;
    margin-right: 30px;
    margin-left: 0;
    color: var(--color2);
}

.red_mute-box {
  width: 200px;
  justify-content: flex-end;
  display: flex;
  height: 100%;
  .SocialBox{
    min-height: 36px;
    justify-content: flex-start;
    .SocialBox--btnBox{
      border-color: var(--white-color);
    }
  }

  &__muteUnmute{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.loaded {
  display: none;
}


.red_time{
  &_space{
    font-weight: 700;
    margin: 0 5px;
  }
    display: flex;
    color: var(--color2);
    position: absolute;
    font-weight: 700;
    left: 10px;
    z-index: 2;
    top: 0;
    bottom: 0;
    align-items: center;
}

.red_track__leftWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .player__Collapse{
    display: none;
  }
}

.red_playerTime__box{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.red_xplayer-title{
  width: 100%;
  display: flex;
  align-items: center;
}

.red_player-title_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  svg {
    width: 40px;
    height: 30px;
    fill: var(--color-14);
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  padding: 2px 5px 2px 0;
  width: 40px;
  height: 24px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &:hover {
    opacity: 1;
  }
}

.red_mute,
.red_unmute{
  svg{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 30px;
  }
}
.red_mute{
  fill: var(--color-1);
}
.red_unmute{
  fill: none;
}



#GeneralBox,
#toTop{
  display: none;
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--color-3);
    }

    &:hover {
      background-color: var(--color-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--color-3);
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}




/*SHAREBOX*/
span{
  &.red_shareblock{
    border-radius: 15px;
    z-index: 10000;
    width: 500px;
    display: block;
    background-color: var(--color-11);
    position: fixed;
    top: 50%;
    left: 20%;
    margin: -100px 0 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    border: 1px solid var(--color-2);
    b {
      border: 4px solid var(--color-1);
      position: absolute;
      top: -20px;
      right: -20px;
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      margin-bottom: -24px;
      background-color: #fff;
      cursor: pointer;
      border-radius: 30px;
      line-height: 40px;
      font-size: 25px;
    }
    h1 {
      padding: 10px 15px;
      margin: 0;
      background: var(--color-11);
      font-family: sans-serif;
      font-size: 25px;
      font-weight: 400;
      display: block;
      color: var(--color-1);
      border-bottom: 4px solid var(--white-color);
    }
    span {
      width: 400px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0;    
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
    }
    a {
      color: var(--color-1);
      font-size: 25px;
      background: url(../images/share.png) 10px 0 no-repeat;
      margin: 10px 10px 10px 0;
      padding: 0 10px 0 25px;
      &.vk {
        background-position: 0 4px;
      }
      &.tw {
        background-position: 0 -56px;
      }
      &.ok {
        background-position: 0 -86px;
      }
      &.mm {
        background-position: 0 -116px;
      }
      &.gp {
        background-position: 0 -146px;
      }
    }
  }
}






.red_blueSocial_vk {
    height: 55px;
    width: 220px;
    background-color: var(--color2); 
    border-radius: 10px;
    &:hover{
      transform: rotate(5deg);
      transition: 0.5s;
    }
  }
.red_blueDownload_vk {
  line-height: 50px;
	text-align: center;
	cursor: pointer;
	color: #FFF;
	transition: all 0.3s;
  
}
.red_blueDownload_vk span {
	transition: all 0.3s;
  font-weight: 700;
  font-size: 18px;
}


.red_blueDownload_vk:hover span {
	letter-spacing: 2px;
}
.red_blueDownload_vk:hover::before {
	opacity: 1;	
}

.red_blueDownload_vk:hover::after {
	opacity: 0;	
}



.red_active__selection{
  &-content{
  display: flex;
  &:hover{
    & a{
      color: var(--color-1) !important;
    }
    .red_active__selection-like{
      fill: var(--color-1);
    }
  }
  a{
    color: var(--color-14);
  }
}
  &-like{
    fill: var(--color-14);
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
}

.red_blueSidebar{
    position: relative;
    flex-direction: column;
    display: flex;
    width: 350px;
    background: var(--color2);
    margin-right: 35px;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 120px;
  &_list{
    flex-direction: column;
    width: 300px;
    display: flex;
    margin-left: 40px;
  }
  & ul{
    font-weight: 700;
    margin-bottom: 0;
  }
  .red_sidebar-header {
    border-radius: 15px;
    padding: 20px 0 10px;
    background: var(--color-10);
    &_logo{
      a{
        color: var(--white-color);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  ul.components {
    padding: 20px 0;
  }
  ul {
    p {
      color: var(--white-color);
      padding: 10px;
      font-size: 18px;
    }
  
  }
  a[aria-expanded="true"] {
    position: relative;
    font-size: 35px;
  }
  a[data-toggle="collapse"] {
    position: relative;
  }
  a.article {
    color: var(--white-color) !important;
    &:hover {
      background: var(--color-10) !important;
      color: var(--white-color) !important;
    }
  }

}


