:root{
    --color-1: #f9dd94;
    --color2: #DA4566;
    --color-3: #EDABAF;
    --color-4: #F4FBF9;
    --color-5: #5b7fa1;
    --color-6: #a5b4cf;
    --color-7: #3a425d;


    --color-8: #ED8285;
    --color-10: var(--color-background);
    --color-11: #f7bfb1;
    --background: #edeef0;
    
    --color-14: #fff;
    --white-color: #fff;
}